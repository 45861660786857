@import "settings";
@import "_common/grid";

.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;

  .error-box {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include respond(XS){
      margin-left: -200px;
      margin-right: -200px;
    }
  }

  .h1 {
    @include respond-property('font-size', 50px, 40px, 35px, 35px, 30px, 25px);
    margin-bottom: 10px;
    font-weight: 900;
    color: #17556A;
  }

  .message {
    @include respond-property('font-size', 20px, 20px, 18px, 18px, 16px, 14px);
    color: #909090;
  }

  .figure {
    width: 544px;
    @include respond-property('width', 800px, 620px);
    position: relative;
    margin: auto;

    @include respond(XS){
      transform: scale(0.8);
    }

    svg.bg {
      position: relative;
      z-index: 10;
      width: 100%;
      height: auto;
    }

    svg.svg {
    }

    svg.word {
      position: absolute;
      z-index: 20;
      animation: wave 3s ease-in-out infinite;

      @keyframes wave {
        0% { transform: translateY(-10px); }
        50% { transform: translateY(10px); }
        100% { transform: translateY(-10px); }
      }

      &.word-3 {
        left: 58%;
        top: 30%;
        animation-duration: 5s;

        @include respond-down(XL){
          top: 25%;
          width: 100px;
        }
        @include respond-down(L){
          top: 22%;
          width: 88px;
          left: 57%;
        }
      }

      &.word-1 {
        top: 37%;
        left: 28%;
        animation-duration: 4.5s;

        @include respond-down(XL){
          top: 33%;
          width: 100px;
        }
        @include respond-down(L){
          top: 31%;
          width: 88px;
          left: 29%;
        }
      }

      &.word-2 {
        left: 45%;
        top: 36%;
        animation-duration: 3s;

        @include respond-down(XL){
          top: 32%;
          width: 80px;
        }
        @include respond-down(L){
          top: 30%;
          width: 68px;
        }
      }
    }

    svg.wave {
      position: absolute;
      z-index: 30;
      top: 50%;
      left: 30%;

      @include respond-down(XL){
        width: 263px;
        top: 48%;
        left: 29%;
      }
      @include respond-down(L){
        top: 47%;
        width: 234px;
      }
    }
  }

  .button {
    margin-bottom: 16px;
  }

  .home-link {
    @include respond-property('font-size', 18px, 16px, 14px, 16px, 12px, 12px);
    display: inline-flex;
    align-items: center;
    color: #FC6D94;
    font-weight: 800;

    svg {
      margin-left: 5px;
      transition: transform 0.25s ease;

      @include respond-down(XL){
        width: 8px;
      }

      path {
        stroke: #FC6D94;
      }
    }

    &:hover {
      svg {
        transform: translateX(5px);
      }
    }
  }
}
